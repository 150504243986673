export default [
  {
    text: "About",
    url: "https://zeph.co"
  },
  {
    text: "Code sample",
    url: "https://github.com/markthomasmiller/portfolio"
  },
  {
    text: "Contact",
    url: "mailto:mark@mayvalellc.com"
  }
];
